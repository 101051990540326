import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import GoogleButton from "react-google-button";

import { API_URL } from "~/config";
import { useStores } from "~/hooks";

export const LoginPage: FC = observer(() => {
  const { uiStore } = useStores();

  if (uiStore.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <a href={`${API_URL}/auth/google/login`}>
        <GoogleButton />
      </a>
    </Container>
  );
});

const Container = styled("div")({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& label + div:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});
