import React, { FC } from "react";
import { Typography } from "@material-ui/core";

import { useStores, useUrlParams } from "~/hooks";
import { ProjectsProvider } from "~/providers/";
import { Timeline } from "~/components/timeline";
import { TimelineSettings } from "~/components/timelineSettings";

export const StatusPage: FC = () => {
  const { timelineStore } = useStores();

  const { startDate, endDate, selectedProjectIds } = useUrlParams();

  timelineStore.setSelectedProjectIds(selectedProjectIds);
  timelineStore.setStartDate(startDate);
  timelineStore.setEndDate(endDate);

  return (
    <ProjectsProvider>
      <TimelineSettings />
      {selectedProjectIds.size > 0 ? (
        <Timeline
          startDate={startDate}
          endDate={endDate}
          selectedProjectIds={selectedProjectIds}
        />
      ) : (
        <Typography align="center">No project selected</Typography>
      )}
    </ProjectsProvider>
  );
};
