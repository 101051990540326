import React, { FC } from "react";
import { Grid } from "@material-ui/core";

import { SizedGrid } from "~/components/styledElements";

import { DateRangePicker } from "./DateRangePicker";
import { TimePicker } from "./TimePicker";

export const DateTimeSelect: FC = () => {
  return (
    <SizedGrid
      height="100%"
      width="100%"
      container
      justify="flex-start"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item>
        <DateRangePicker />
      </Grid>
      <Grid item>
        <TimePicker target="start" />
      </Grid>
      <Grid item>
        <TimePicker target="end" />
      </Grid>
    </SizedGrid>
  );
};
