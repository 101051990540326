import { useParams } from "react-router-dom";
import { Dayjs } from "dayjs";

import { ID } from "@opacity/domain";

import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "~/config";
import { urlToDayjs, urlToSelectedProjectIds } from "~/utils";

import { useQuery } from "./useQuery.hook";

export const useUrlParams = (): {
  startDate: Dayjs;
  endDate: Dayjs;
  selectedProjectIds: Set<ID>;
} => {
  const { startDate: urlStartDate, endDate: urlEndDate } = useParams<{
    startDate: string;
    endDate: string;
  }>();

  const urlSelectedProjects = useQuery().get("p");

  const startDate = urlStartDate
    ? urlToDayjs(urlStartDate)
    : DEFAULT_START_DATE;
  const endDate = urlEndDate ? urlToDayjs(urlEndDate) : DEFAULT_END_DATE;
  const selectedProjectIds = urlToSelectedProjectIds(urlSelectedProjects);

  return {
    startDate,
    endDate,
    selectedProjectIds,
  };
};
