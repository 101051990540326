import { makeStyles } from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles({
  "@global": {
    body: {
      fontFamily: "'Roboto', sans-serif",
    },

    "html, body, #root": {
      height: "100vh",
    },

    "#root": {
      background: "#f8f8f8",
    },

    a: {
      textDecoration: "none",
      color: "inherit",

      "&:hover, &:focus, &:visited": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
});
