import { Group, Line, Segment } from "timelines-chart";
import { timeFormat } from "d3-time-format";
import {
  timeDay,
  timeHour,
  timeMinute,
  timeMonth,
  timeSecond,
  timeWeek,
  timeYear,
} from "d3-time";

import { ID, Project } from "@opacity/domain";

export const filterAndFormatProjectListForTimelinesChart = (
  selectedProjectIds: Set<ID>,
  projectList?: Project[]
): Group[] => {
  if (!projectList) {
    return [];
  }

  return projectList
    .filter(project => selectedProjectIds.has(project.id))
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    .map(project => {
      const data: Line[] = project.devices
        .slice()
        .sort((a, b) => a.id - b.id)
        .map(device => {
          const segmentList: Segment[] = device.downtimes.map(downtime => ({
            timeRange: [downtime.start, downtime.end],
            val: device.deviceuid,
          }));
          return {
            label: String(device.id),
            data: segmentList,
          };
        });
      return {
        group: project.name,
        data,
      };
    });
};

// based on the example presented at https://github.com/d3/d3-time-format
const formatMillisecond = timeFormat(".%L");
const formatSecond = timeFormat(":%S");
const formatMinute = timeFormat("%H:%M");
const formatHour = timeFormat("%H");
const formatDay = timeFormat("%a %d");
const formatWeek = timeFormat("%b %d");
const formatMonth = timeFormat("%B");
const formatYear = timeFormat("%Y");

export const xTickFormat = (date: Date): string =>
  (timeSecond(date) < date
    ? formatMillisecond
    : timeMinute(date) < date
    ? formatSecond
    : timeHour(date) < date
    ? formatMinute
    : timeDay(date) < date
    ? formatHour
    : timeMonth(date) < date
    ? timeWeek(date) < date
      ? formatDay
      : formatWeek
    : timeYear(date) < date
    ? formatMonth
    : formatYear)(date);

export const GRAPH_TIME_FORMAT = "%d-%m-%Y %H:%M";
