import { gql } from "@apollo/client";

export const getAllSensorDowntimes = gql`
  query GetAllSensorDowntimes {
    getProjectList {
      projectList {
        id
        name
        devices {
          id
          deviceuid
          downtimes {
            start
            end
          }
        }
      }
    }
  }
`;
