import React, { Suspense, useEffect } from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import { client } from "~/api";
import { ErrorBoundary } from "~/components/errorBoundary";
import { APP_ROOT } from "~/config";
import { SnackbarProvider, StoresProvider } from "~/providers";
import { GlobalRouter } from "~/routers";
import { opacityTheme, useGlobalStyles } from "~/styles";
import { checkServerVersion, resetLibraries } from "~/utils";

resetLibraries();

const rootElement = document.getElementById(APP_ROOT);

function ReactApp(): JSX.Element {
  useGlobalStyles();

  useEffect(() => {
    checkServerVersion();
  }, []);

  return (
    <ThemeProvider theme={opacityTheme}>
      <CssBaseline />
      <SnackbarProvider>
        <BrowserRouter>
          <StoresProvider>
            <ErrorBoundary>
              <Suspense fallback={<div />}>
                <ApolloProvider client={client}>
                  <GlobalRouter />
                </ApolloProvider>
              </Suspense>
            </ErrorBoundary>
          </StoresProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

render(<ReactApp />, rootElement);
