import React, { FC } from "react";
import { styled } from "@material-ui/core/styles";

import { ErrorBoundary } from "~/components/errorBoundary";
import { SizedGrid } from "~/components/styledElements";
import { PrivateRouter } from "~/routers";

import { Header } from "./Header";

export const RootLayout: FC = () => (
  <Container>
    <Header />
    <Main container width="100vw">
      <BodyContainer height="100%" item>
        <ErrorBoundary>
          <PrivateRouter />
        </ErrorBoundary>
      </BodyContainer>
    </Main>
  </Container>
);

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  height: "100%",
});

const Main = styled(SizedGrid)(({ theme }) => ({
  height: `calc(100vh - ${theme.headerHeight + theme.headerMargin}px)`,
  marginTop: theme.headerMargin,
}));

const BodyContainer = styled(SizedGrid)(({ theme }) => ({
  flexGrow: 1,
  boxShadow: theme.shadows[2],
  overflow: "auto",
}));
