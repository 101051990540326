import React, { FC, memo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RoutePath } from "~/domain/enums";
import { LogoutPage, StatusPage } from "~/pages";

export const PrivateRouter: FC = memo(
  function PrivateRouter() {
    return (
      <Switch>
        <Route exact path={RoutePath.Logout} component={LogoutPage} />
        <Route
          path={`${RoutePath.Status}/:startDate?/:endDate?`}
          component={StatusPage}
        />
        <Redirect to={RoutePath.Status} />
      </Switch>
    );
  },
  () => true
);
