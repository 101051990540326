import { createContext } from "react";

import { TimelineStore, UiStore } from "~/stores";

export interface StoresContext {
  uiStore: UiStore;
  timelineStore: TimelineStore;
}

export const storesContext = createContext<StoresContext | null>(null);
