import React, { FC } from "react";
import { Link } from "react-router-dom";
import { AppBar, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import { VivacityLogo } from "~/components/icons";
import { SizedGrid } from "~/components/styledElements";
import { UserMenu } from "~/components/userMenu";

// TODO: "easily" expose the JWT

export const Header: FC = () => {
  return (
    <AppBar position="static" color="inherit" elevation={1}>
      <HeaderContainer container justify="center" alignItems="center">
        <SizedGrid
          container
          justify="flex-start"
          alignItems="center"
          height="100%"
          item
          xs={2}
        >
          <SizedLink to="/">
            <Logo />
          </SizedLink>
        </SizedGrid>
        <Grid item xs={8}>
          <Typography align="center" variant="h6">
            Opacity
          </Typography>
        </Grid>
        <SizedGrid
          container
          item
          xs={2}
          justify="flex-end"
          alignItems="center"
          height="100%"
        >
          <UserMenu />
        </SizedGrid>
      </HeaderContainer>
    </AppBar>
  );
};

const HeaderContainer = styled(Grid)(({ theme }) => ({
  padding: `0px  ${theme.spacing(2)}px`,
  height: theme.headerHeight,
  background: theme.elementBackground,
}));

const Logo = styled(VivacityLogo)({
  width: 70,
});

const SizedLink = styled(Link)({
  display: "flex",
  alignItems: "center",
});
