/* eslint-disable max-len */
import React, { FC, SVGAttributes } from "react";

export interface VivacityLogoProps extends SVGAttributes<SVGElement> {
  primary?: string;
  secondary?: string;
}

export const VivacityLogo: FC<VivacityLogoProps> = ({
  primary = "#262626",
  secondary = "#0f7a6d",
  ...rest
}) => {
  return (
    <svg
      id="vivacity-logo_svg__Tube_map"
      data-name="Tube map"
      viewBox="0 0 605 331.75"
      {...rest}
    >
      <defs>
        <clipPath
          id="vivacity-logo_svg__clip-path"
          transform="translate(2455 -4793)"
        >
          <path
            d="M-1744 5196h-781v-489h781v489zm-112.93-313h-576.73v117h576.73v-117z"
            fill="none"
          />
        </clipPath>
        <style>
          {`.vivacity-logo_svg__cls-3{fill:${secondary}}.vivacity-logo_svg__cls-4{fill:${primary}}`}
        </style>
      </defs>
      <g clipPath="url(#vivacity-logo_svg__clip-path)">
        <path
          className="vivacity-logo_svg__cls-3"
          d="M-2348.28 4828.93l11.82 11.84 11.75 11.91 11.71 11.95 11.66 12 .41.43v.65c.16 8.32.24 16.63.35 24.95s.22 16.63.26 24.95l.19 24.95.08 24.95-.73-1.74 18.5 18.25 18.42 18.33q9.23 9.15 18.37 18.38l18.32 18.43 18.24 18.51 18.16 18.59 18.07 18.68q9 9.38 17.93 18.82-9.55-8.82-19-17.74l-18.86-17.88-18.77-18-18.7-18.05-18.61-18.13q-9.31-9.06-18.56-18.19l-18.51-18.23-18.44-18.31-.73-.72v-1l.08-24.95.19-24.95c0-8.32.16-16.63.26-24.95s.19-16.63.35-24.95l.43 1.08-11.24-12.39-11.18-12.44-11.14-12.48z"
          transform="translate(2455 -4793)"
        />
        <path
          className="vivacity-logo_svg__cls-3"
          d="M-1923.27 4858.84l-10.72 11.58-10.78 11.52-.18.19h-.28l-30.91.52-30.91.4-30.91.31-30.91.26c-20.61.09-41.21.27-61.82.28s-41.21.12-61.82.06l2.48-2.48-.07 37.25-.17 37.25v.93l-.64.64-19.3 19.52-19.36 19.46-19.39 19.43-19.44 19.38-.37.37h-.49l-25.75-.44.54-.22-14.24 13.64-14.31 13.57 13.69-14.18 13.76-14.11.21-.22h.33l25.75-.44-.87.36 18.94-19.87 19-19.82 19-19.79 19.08-19.73-.64 1.57-.17-37.25-.07-37.25V4879h2.48c20.61-.06 41.21 0 61.82.06s41.21.19 61.82.28l30.91.26 30.91.31 30.91.4 30.91.52-.46.19 11.17-11.14z"
          transform="translate(2455 -4793)"
        />
        <path
          className="vivacity-logo_svg__cls-3"
          d="M-2037.5 4793l.4 14.54.34 14.54.55 29.07.43 29.07.31 29.07v.81l-.59.61-14.38 14.92-14.4 14.9c-9.59 9.95-19.27 19.79-28.91 29.7l.7-1.72v33.74l-.08 33.75-.71-1.69 20.25 20.38q10.14 10.18 20.21 20.43l-1.37-.57 26.93.31 26.93.41 26.93.53q13.47.29 26.93.69-13.47.4-26.93.69l-26.93.53-26.93.41-26.93.31h-.78l-.59-.58q-10.31-10-20.56-20.07l-20.52-20.12-.7-.69v-1l-.08-33.75v-34.74l.7-.71c9.7-9.84 19.35-19.72 29.09-29.52l14.6-14.71 14.62-14.69-.58 1.42.31-29.07.43-29.07.55-29.07.34-14.54z"
          transform="translate(2455 -4793)"
        />
        <path
          className="vivacity-logo_svg__cls-3"
          d="M-1942.21 4969.88c-5.33 5.74-10.62 11.53-16 17.24l-16.05 17.17-16.05 17.17-16.13 17.1-.43.46h-.66l-44.51.43-22.26.18-22.26.11-22.26.11-22.26.06-44.51.08h-1l-.72-.71-51.84-50.44-.72-.71v-1l.34-60 .26-30 .33-30v-.65l.45-.41 16-14.5 16.1-14.45 16.1-14.45 16.16-14.37-15.55 15-15.62 15-15.62 15-15.67 14.91.46-1.06.33 30 .26 30 .34 60-.72-1.7 52 50.26-1.74-.7 44.51.08 22.26.06 22.26.11 22.26.11 22.26.18 44.51.43-1.09.46 16.53-16.71 16.6-16.64 16.6-16.64c5.59-5.56 11.19-11.05 16.75-16.57z"
          transform="translate(2455 -4793)"
        />
        <path
          className="vivacity-logo_svg__cls-3"
          d="M-2377 4925.5l14.81-.39 14.81-.29 29.61-.58 29.61-.42 29.61-.31 29.61-.23 29.61-.17 29.61-.06h29.62l-1.75.72L-2121 4903q10.4-10.37 20.9-20.65l20.94-20.61 21-20.53.54-.53h.71l27.7.37 13.85.22 13.85.28-.64.27 14.7-14c4.89-4.7 9.85-9.32 14.77-14-4.69 4.9-9.33 9.83-14.06 14.69l-14.13 14.62-.25.26h-.39l-13.85.28-13.85.22-27.7.37 1.26-.52-20.58 21-20.66 20.89q-10.33 10.44-20.7 20.85l-20.76 20.8-.72.73h-30.64l-29.61-.06-29.61-.17-29.61-.23-29.61-.31-29.61-.42-29.61-.58-14.81-.29z"
          transform="translate(2455 -4793)"
        />
        <path
          className="vivacity-logo_svg__cls-3"
          d="M-1977.5 5095l-.44-14.12-.37-14.12c-.19-9.42-.44-18.83-.58-28.25l-.24-14.12-.2-14.12-.32-28.25 2.15 2.15-25.62.26c-8.54.11-17.08.11-25.62.17h-1l-.73-.73-16.89-16.89-16.81-17-16.79-17-16.72-17.05q-8.37-8.51-16.67-17.1l-16.62-17.16-16.54-17.23-16.46-17.32-.19-.2v-.25l.3-10.86.35-10.86.35 10.86.3 10.86-.19-.45 17.23 16.54 17.15 16.63 17.07 16.7q8.53 8.35 17 16.75l17 16.8 16.9 16.87 16.88 16.89 16.8 17-1.73-.72h25.62l25.62.12h2.18v2.14l-.32 28.25-.2 14.13-.24 14.13c-.14 9.42-.38 18.83-.58 28.25l-.37 14.13z"
          transform="translate(2455 -4793)"
        />
        <path
          className="vivacity-logo_svg__cls-3"
          d="M-2222.44 5124.75l11.85-12.57 11.92-12.5q11.93-12.49 24-24.87c8-8.28 16.07-16.48 24.11-24.73l24.23-24.61c8.06-8.21 16.22-16.34 24.34-24.5s16.24-16.31 24.42-24.41l24.51-24.33 24.62-24.22.63-.62h.84l26.75.33 26.75.43 26.75.56q13.38.33 26.75.77-13.38.45-26.75.77l-26.75.56-26.75.43-26.75.33 1.48-.61-24.24 24.59-24.35 24.49q-12.16 12.26-24.44 24.4c-8.17 8.11-16.3 16.26-24.52 24.31l-24.63 24.21c-8.25 8-16.46 16.1-24.75 24.08s-16.56 16-24.89 23.95l-12.51 11.91z"
          transform="translate(2455 -4793)"
        />
      </g>
      <path
        className="vivacity-logo_svg__cls-4"
        d="M2.86 213H605l-14.93-6H16.2l-13.34 6zM0 84h602.14l-14.92 6H13.34L0 84zM550 147h10v36h-10z"
      />
      <path
        className="vivacity-logo_svg__cls-4"
        d="M-2407.93 4959.89v15.78l-30.67-70.17 1.32.81c6.79 4.17 15 13.56 18.13 20.87l8.48 19.51a33.13 33.13 0 012.74 13.2zM-2330.09 4906h.17v61a9 9 0 01-9 9v-61.17a8.83 8.83 0 018.83-8.83zM-1979.93 4977v-62h-7.38a33 33 0 01-18.31-5.54l-3.69-2.46h68.56l-3.94 2.59a33 33 0 01-18.11 5.41h-8.14v53a9 9 0 01-9 9M-2079.05 4957.07a15 15 0 01-6.65 17 20.08 20.08 0 01-9.91 3c-10.73.19-17.67-7.83-20.47-12.08-9.09-13.78-9.31-36.26 1.84-48.89 2.51-2.85 8.76-9.93 18.64-10a20.69 20.69 0 0111.2 3.5 13 13 0 015.37 14.71c-1.18-2-7.81-9.14-15.54-9.07-7.49.07-12.23 5.44-14.13 7.6-8.45 9.58-6.68 26.81.22 37.26 2.12 3.22 5.78 9.13 13.92 9 8.39-.15 14.65-8.57 15.54-11.91zM-2404.76 4959.92v15.74l30.67-70.17-1.32.81c-6.79 4.17-15 13.56-18.13 20.87l-8.46 19.48a33.31 33.31 0 00-2.76 13.27zM-2187.93 4921.22v-15.73l-30.67 70.17 1.32-.81c6.79-4.17 15-13.56 18.13-20.87l8.46-19.47a33.37 33.37 0 002.76-13.29zM-2184.76 4921.22v-15.73l30.67 70.17-1.32-.81c-6.79-4.17-15-13.56-18.13-20.87l-8.46-19.47a33.37 33.37 0 01-2.76-13.29zM-2030.39 4907h.17v61a9 9 0 01-9 9v-61.17a8.83 8.83 0 018.83-8.83zM-2269.09 4960.31v15.78l-30.67-70.17 1.32.81c6.79 4.17 15 13.56 18.13 20.87l8.48 19.51a33.13 33.13 0 012.74 13.2zM-2265.93 4960.34v15.74l30.67-70.17-1.32.81c-6.79 4.17-15 13.56-18.13 20.87l-8.46 19.48a33.31 33.31 0 00-2.76 13.27zM-1900.52 4933.57v12.9l-30.17-39.94 9.13 3.25a15.15 15.15 0 017 5.17zM-1900.52 4933.57v12.9l31.21-39.94-9.63 3.3a15.22 15.22 0 00-7.09 5.07z"
        transform="translate(2455 -4793)"
      />
    </svg>
  );
};
