import React, { FC, memo, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react-lite";
import dayjs, { Dayjs } from "dayjs";
import { useQuery } from "@apollo/client";
import { AutoSizer } from "react-virtualized";
import { Typography, useTheme } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import TimelinesChart from "timelines-chart";

import {
  GetDeviceDowntimesByProjectWithinIntervalPayload,
  ID,
} from "@opacity/domain";

import { getDeviceDowntimesByProjectWithinInterval } from "~/api";
import { Loading } from "~/components/loading";
import { useStores } from "~/hooks";

import {
  GRAPH_TIME_FORMAT,
  filterAndFormatProjectListForTimelinesChart,
  xTickFormat,
} from "./utils";

type Range<DomainType> = [DomainType, DomainType];

interface TimelineProps {
  startDate: Dayjs;
  endDate: Dayjs;
  selectedProjectIds: Set<ID>;
}

export const Timeline: FC<TimelineProps> = memo(
  observer(function Timeline({ startDate, endDate, selectedProjectIds }) {
    const { timelineStore } = useStores();
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);

    const { loading, error, data } = useQuery<{
      getDeviceDowntimesByProjectWithinInterval: GetDeviceDowntimesByProjectWithinIntervalPayload;
    }>(getDeviceDowntimesByProjectWithinInterval, {
      variables: {
        input: { startDate, endDate },
      },
    });

    const filteredData = useMemo(
      () =>
        filterAndFormatProjectListForTimelinesChart(
          selectedProjectIds,
          data?.getDeviceDowntimesByProjectWithinInterval.projectList
        ),
      [data, selectedProjectIds]
    );

    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        const onGraphZoom = (
          zoomX: Range<number | Date | null> | null
        ): void => {
          if (zoomX && zoomX.length === 2 && zoomX[0] && zoomX[1]) {
            const [currentStartDate, currentEndDate] = zoomX;
            timelineStore.setZoomedDateLimits(
              dayjs(currentStartDate),
              dayjs(currentEndDate)
            );
          }
        };

        const chart = TimelinesChart();
        chart
          .topMargin(0)
          .leftMargin(theme.spacing(40))
          .rightMargin(theme.spacing(40))
          .zQualitative(true)
          .timeFormat(GRAPH_TIME_FORMAT)
          .xTickFormat(xTickFormat)
          .onZoom(onGraphZoom)
          .data(filteredData)(container);
        timelineStore.setChart(chart);
      }
      return () => {
        if (container) {
          container.innerHTML = "";
        }
        const tooltips = document.getElementsByClassName("chart-tooltip");
        Array.from(tooltips).forEach(tooltip => {
          tooltip.remove();
        });
      };
    }, [filteredData, theme, timelineStore]);

    if (loading) {
      return <Loading />;
    } else if (error) {
      return <Typography align="center">An error occurred.</Typography>;
    }

    if (filteredData.length === 0) {
      return (
        <>
          <Typography variant="h5" align="center">
            No downtimes! \o/
          </Typography>
          <NoDowntimesSubheader align="center">
            Well, at least for the selected projects and time range...
          </NoDowntimesSubheader>
        </>
      );
    }

    return (
      <GraphContainer>
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width }} ref={containerRef} />
          )}
        </AutoSizer>
      </GraphContainer>
    );
  }),
  (prevProps, nextProps) =>
    prevProps.startDate.isSame(nextProps.startDate) &&
    prevProps.endDate.isSame(nextProps.endDate) &&
    prevProps.selectedProjectIds.size === nextProps.selectedProjectIds.size &&
    Array.from(prevProps.selectedProjectIds).every(prevId =>
      nextProps.selectedProjectIds.has(prevId)
    )
);

const GraphContainer = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(5),
  background: theme.elementBackground,
  height: `calc(100vh - ${
    theme.headerHeight +
    theme.headerMargin +
    theme.controlBarHeight +
    theme.controlBarMargin
  }px)`,
  overflowX: "hidden",
  overflowY: "auto",
}));

const NoDowntimesSubheader = styled(Typography)({
  fontStyle: "italic",
});
