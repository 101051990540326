import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import { RoutePath } from "~/domain/enums";
import { RootLayout } from "~/layouts";
import { LoginPage } from "~/pages";

import { PrivateRoute } from "./PrivateRoute";

export const GlobalRouter: FC = () => {
  return (
    <Switch>
      <Route path={RoutePath.Login} component={LoginPage} />
      <PrivateRoute path="/" component={RootLayout} />
    </Switch>
  );
};
