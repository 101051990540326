/* eslint-disable @typescript-eslint/naming-convention */
import { createMuiTheme } from "@material-ui/core/styles";

const SPACING = 6;

export const opacityTheme = createMuiTheme(
  {
    palette: {
      type: "light",
      primary: {
        light: "#B1DAFF",
        main: "#2397ff",
        dark: "#2397ff",
      },
      secondary: {
        light: "#F9AA33",
        main: "#F9AA33",
        dark: "#F9AA33",
      },
    },
    typography: {
      fontSize: SPACING * 2,
    },
    spacing: SPACING,
    overrides: {
      MuiInputAdornment: {
        positionEnd: {
          visibility: "hidden",
        },
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottom: "unset",
            transition: "border 0.2s",
          },
        },
        root: {
          "&:hover .MuiInputAdornment-positionEnd": {
            visibility: "visible",
          },
        },
      },
    },
  },
  {
    elementBackground: "#fff",
    buttonHover: "#e4e7e7",
    headerHeight: SPACING * 8,
    headerMargin: SPACING * 2,
    controlBarHeight: SPACING * 10,
    controlBarMargin: SPACING * 2,
  }
);
