import axios from "axios";
import satisfies from "semver/functions/satisfies";

import { GetVersionPayload } from "@opacity/domain";

import { API_URL } from "~/config";

import { Logger } from "./logger";

const isServerVersionSatisfying = (serverVersion: string): boolean => {
  return satisfies(serverVersion, __REQUIRED_SERVER_VERSION__);
};

export const checkServerVersion = async (): Promise<void> => {
  try {
    const {
      data: { version: serverVersion },
    }: { data: GetVersionPayload } = await axios.get(`${API_URL}/version`);
    if (!isServerVersionSatisfying(serverVersion)) {
      Logger.error(
        `Server version ${serverVersion} does NOT satisfy client's peer dependency: ${__REQUIRED_SERVER_VERSION__}`
      );
    }
  } catch (err) {
    Logger.error(err);
  }
};
