import { RootStore } from "./root.store";

export abstract class ChildStore {
  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    // attach root store to any child store
    this.rootStore = rootStore;
  }
}
