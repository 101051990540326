import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";

import { useStores } from "~/hooks";
import { dayjsToUrl, selectedProjectIdsToUrl } from "~/utils";

export const ApplyFilters = observer(() => {
  const history = useHistory();

  const {
    timelineStore: { startDate, endDate, selectedProjectIds },
  } = useStores();

  const handleApplyFilters = (): void => {
    const newUrl = `/status/${dayjsToUrl(startDate)}/${dayjsToUrl(
      endDate
    )}${selectedProjectIdsToUrl(selectedProjectIds)}`;
    history.push(newUrl);
  };

  return (
    <Button color="primary" variant="contained" onClick={handleApplyFilters}>
      Apply filters
    </Button>
  );
});
