import dayjs from "dayjs";

import { getApiUrl } from "~/utils";

export const API_URL = getApiUrl();
export const APP_ROOT = "root";

export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm";
export const MIN_DATE = dayjs("01-01-2020");
export const MAX_DATE = dayjs().endOf("day");
export const DEFAULT_START_DATE = dayjs().startOf("day");
export const DEFAULT_END_DATE = dayjs().endOf("day");
