import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import ReactDateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";

import { useStores } from "~/hooks";
import { MAX_DATE, MIN_DATE } from "~/config";

export const DateRangePicker: FC = observer(() => {
  const {
    timelineStore,
    timelineStore: { startDate, endDate },
  } = useStores();

  const handleDateRangeChange = ([newInputStartDate, newInputEndDate]: [
    Date,
    Date
  ]): void => {
    const newStartDate = dayjs(newInputStartDate)
      .set("hour", startDate.hour())
      .set("minute", startDate.minute());
    const newEndDate = dayjs(newInputEndDate)
      .set("hour", endDate.hour())
      .set("minute", endDate.minute());
    timelineStore.setStartDate(newStartDate);
    timelineStore.setEndDate(newEndDate);
  };

  return (
    <ReactDateRangePicker
      value={[startDate.toDate(), endDate.toDate()]}
      onChange={handleDateRangeChange}
      minDate={MIN_DATE.toDate()}
      maxDate={MAX_DATE.toDate()}
      showLeadingZeros
      clearIcon={null}
      format="dd-MM-y"
    />
  );
});
