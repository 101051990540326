import dayjs from "dayjs";
import "dayjs/locale/en-gb";

// react-daterange-picker style
// see https://github.com/wojtekmaj/react-daterange-picker/issues/88
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

export const resetLibraries = (): void => {
  dayjs.locale("en-gb");
};
