import React, { FC, ReactText, createRef } from "react";
import {
  SnackbarProvider as NotistackProvider,
  ProviderContext,
} from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export const SnackbarProvider: FC = ({ children }) => {
  const notistackRef = createRef<ProviderContext>();

  const onClickDismiss = (key: ReactText) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <NotistackProvider
      ref={notistackRef}
      action={key => (
        <IconButton color="inherit" size="small" onClick={onClickDismiss(key)}>
          <Close fontSize="inherit" />
        </IconButton>
      )}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {children}
    </NotistackProvider>
  );
};
