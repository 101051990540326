import React, { FC, useEffect, useState } from "react";

import { storesContext } from "~/contexts";

import { RootStore } from "~/stores/root.store";

export const StoresProvider: FC = ({ children }) => {
  const [rootStore, setRootStore] = useState<RootStore | null>(null);

  useEffect(() => {
    const initStore = async (): Promise<void> => {
      const newRootStore = await RootStore.instantiate();
      setRootStore(newRootStore);
    };
    if (!rootStore) {
      initStore();
    }
  }, [rootStore]);

  if (!rootStore) {
    return null;
  }

  return (
    <storesContext.Provider value={rootStore}>
      {children}
    </storesContext.Provider>
  );
};
