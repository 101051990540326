import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { Button, ButtonGroup, Tooltip, Typography } from "@material-ui/core";
import { ZoomIn, ZoomOut } from "@material-ui/icons";

import { SizedGrid } from "~/components/styledElements";
import { useStores } from "~/hooks";

export const GraphControllers: FC = observer(() => {
  const {
    timelineStore: { chart, zoomedTimeRange, startDate, endDate, timeRange },
  } = useStores();

  const onZoomReset = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chart.zoomX([] as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chart.zoomY([] as any);
  };

  const onZoomIn = (): void => {
    // TODO
  };

  const onZoomOut = (): void => {
    // TODO
  };

  return (
    <SizedGrid
      height="100%"
      width="100%"
      container
      justify="flex-end"
      alignItems="center"
    >
      <ButtonGroup color="primary">
        {/* <Tooltip
          title={<Typography variant="body2">Time</Typography>}
          placement="top"
        > */}
        <Button disabled onClick={onZoomIn}>
          <ZoomIn />
        </Button>
        {/* </Tooltip> */}
        <Tooltip
          title={<Typography variant="body2">All</Typography>}
          placement="top"
        >
          <Button onClick={onZoomReset}>Reset</Button>
        </Tooltip>
        {/* <Tooltip
          title={<Typography variant="body2">Time</Typography>}
          placement="top"
        > */}
        <Button disabled onClick={onZoomOut}>
          <ZoomOut />
        </Button>
        {/* </Tooltip> */}
      </ButtonGroup>
    </SizedGrid>
  );
});
