import { configure } from "mobx";

import { TimelineStore } from "./timeline.store";
import { UiStore } from "./ui.store";

export class RootStore {
  private static instance: RootStore;
  public uiStore: UiStore;
  public timelineStore: TimelineStore;

  private constructor() {
    // global mobX settings
    configure({
      enforceActions: "always",
    });

    // initialise each store
    this.uiStore = new UiStore(this);
    this.timelineStore = new TimelineStore(this);
  }

  static async instantiate(): Promise<RootStore> {
    if (RootStore.instance) {
      return RootStore.instance;
    }

    const rootStore = new RootStore();

    // singleton instance
    RootStore.instance = rootStore;
    return rootStore;
  }
}
