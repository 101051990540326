import { gql } from "@apollo/client";

export const getDeviceDowntimesByProjectWithinInterval = gql`
  query GetDeviceDowntimesByProjectWithinInterval(
    $input: GetDeviceDowntimesByProjectWithinIntervalInput!
  ) {
    getDeviceDowntimesByProjectWithinInterval(input: $input) {
      projectList {
        id
        name
        devices {
          id
          deviceuid
          downtimes {
            start
            end
          }
        }
      }
    }
  }
`;
