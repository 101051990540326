import dayjs, { Dayjs } from "dayjs";

import { ID } from "@opacity/domain";

import { Logger } from "./logger";

export const urlToDayjs = (urlDatetime: string): Dayjs => {
  try {
    const [date, time] = urlDatetime.split("_");
    const [year, month, day] = date.split("-");
    let hour = 0;
    let minute = 0;
    if (time) {
      [hour, minute] = time.split("-").map(i => parseInt(i, 10));
    }
    return dayjs()
      .year(parseInt(year, 10))
      .month(parseInt(month, 10) - 1) // 0 based month count
      .date(parseInt(day, 10))
      .hour(hour)
      .minute(minute);
  } catch (err) {
    Logger.error(`Invalid given URL date: ${urlDatetime}`);
    Logger.error(err);
    return dayjs();
  }
};

export const urlToSelectedProjectIds = (
  urlSelectedProjects: string | null
): Set<ID> => {
  if (urlSelectedProjects === null) {
    return new Set();
  }

  const selectedProjectIdList = urlSelectedProjects
    .split(",")
    .map(projectId => parseInt(projectId, 10) as ID);

  return new Set(selectedProjectIdList);
};
