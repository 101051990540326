import { FC } from "react";

import { RoutePath } from "~/domain/enums";

import { useStores } from "~/hooks";

export const LogoutPage: FC = () => {
  const { uiStore } = useStores();
  uiStore.logout();

  location.assign(RoutePath.Login as string);
  return null;
};
