import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";

import { GetProjectListPayload } from "@opacity/domain";

import { getProjectList } from "~/api";
import { useStores } from "~/hooks";
import { Loading } from "~/components/loading";

export const ProjectsProvider: FC = observer(function ProjectsProvider({
  children,
}) {
  const {
    timelineStore,
    timelineStore: { projects },
  } = useStores();

  const { loading, error, data } = useQuery<{
    getProjectList: GetProjectListPayload;
  }>(getProjectList);

  useEffect(() => {
    if (data && data.getProjectList.projectList.length > 0) {
      timelineStore.setProjects(data.getProjectList.projectList);
    }
  }, [data, timelineStore]);

  if (loading || !projects) {
    return <Loading />;
  }
  if (error) {
    return <Typography>Sorry, an error occurred.</Typography>;
  }

  return <>{children}</>;
});
