import React, { ElementType, FC, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Loading } from "~/components/loading";
import { RoutePath } from "~/domain/enums";
import { useStores } from "~/hooks";

interface PrivateRouteProps {
  component: ElementType;
  path: string;
}

export const PrivateRoute: FC<PrivateRouteProps> = observer(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ({ component: Component, ...rest }) => {
    const {
      uiStore: { isAuthenticated },
      uiStore,
    } = useStores();
    const [isAttemptComplete, setIsAttemptComplete] = useState<boolean>(
      isAuthenticated
    );

    useEffect(() => {
      const attemptSilentRefresh = async (): Promise<void> => {
        await uiStore.silentTokenRefresh();
        setIsAttemptComplete(true);
      };

      if (!isAuthenticated) {
        attemptSilentRefresh();
      }
    }, [isAuthenticated, uiStore]);
    uiStore.silentTokenRefresh();

    if (!isAttemptComplete) {
      return <Loading />;
    }

    return (
      <Route
        {...rest}
        render={routeProps =>
          isAuthenticated ? (
            <Component {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: RoutePath.Login,
                state: { from: routeProps.location },
              }}
            />
          )
        }
      />
    );
  }
);
