import React, { FC, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { AccountCircle, FileCopy } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import { RoutePath } from "~/domain/enums";

import { useStores } from "~/hooks";
import { UiStore } from "~/stores";
import { Logger } from "~/utils";

export const UserMenu: FC = observer(() => {
  const {
    uiStore: { user: futureUser },
  } = useStores();

  const iconRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const toggleMenu = (): void => {
    setIsOpen(!isOpen);
  };

  const copyJwt = async (): Promise<void> => {
    if (!UiStore.token) {
      return;
    }
    try {
      await navigator.clipboard.writeText(UiStore.token);
      enqueueSnackbar("Report copied to clipboard", { variant: "info" });
    } catch (err) {
      Logger.error(err);
      enqueueSnackbar(
        "An error occurred while copying report to clipboard. Please try to report the issue directly",
        { variant: "error" }
      );
    }
  };

  // TODO: grab the user with the JWT
  const user = futureUser ?? {
    email: "coming.soon@vivacitylabs.com",
    name: "Camille Doe",
  };

  return (
    <>
      <IconButton size="small" ref={iconRef} onClick={toggleMenu}>
        <SizedAvatar>
          <Typography variant="body2">
            {(user.name || user.email)[0].toUpperCase()}
          </Typography>
        </SizedAvatar>
      </IconButton>
      <Menu
        anchorEl={iconRef.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <StyledMenuItem disabled>
          <Typography variant="button">{user.name}</Typography>&nbsp;
          <AccountCircle />
        </StyledMenuItem>
        <StyledMenuItem onClick={copyJwt}>
          <Typography variant="button">Copy JWT</Typography>&nbsp;
          <FileCopy />
        </StyledMenuItem>
        <Link to={RoutePath.Logout}>
          <StyledMenuItem onClick={handleClose}>Logout</StyledMenuItem>
        </Link>
      </Menu>
    </>
  );
});

const SizedAvatar = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(4),
}));

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  justifyContent: "space-between",
});
