import { gql } from "@apollo/client";

export const getMe = gql`
  query GetMe {
    getMe {
      user {
        email
      }
    }
  }
`;
