import React, { ChangeEvent, FC, ReactNode } from "react";
import { observer } from "mobx-react-lite";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import { ID } from "@opacity/domain";

import { useStores } from "~/hooks";

export const ProjectSelect: FC = observer(() => {
  const {
    timelineStore,
    timelineStore: { projects, selectedProjectIds },
  } = useStores();

  const handleProjectChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    timelineStore.setSelectedProjectIds(new Set(event.target.value as ID[]));
  };

  const renderValue = (value: unknown): ReactNode => {
    const projectIds = value as ID[];
    if (selectedProjectIds.size === 0) {
      return "";
    }
    const projectNames = projectIds
      .map(id => projects.get(id)?.name)
      .filter(Boolean)
      .join(", ");
    return `(${selectedProjectIds.size}) ${projectNames}`;
  };

  // TODO: add a select all/unselect all button

  return (
    <FullWidthFormControl>
      <InputLabel id="project">Projects</InputLabel>
      <Select
        id="project-select"
        value={Array.from(selectedProjectIds.values())}
        renderValue={renderValue}
        multiple
        onChange={handleProjectChange}
        labelId="project"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {Array.from(projects.values()).map(({ id, name }) => (
          <ProjectEntry key={id} value={id}>
            <Checkbox color="primary" checked={selectedProjectIds.has(id)} />
            <ListItemText primary={name} />
          </ProjectEntry>
        ))}
      </Select>
    </FullWidthFormControl>
  );
});

const FullWidthFormControl = styled(FormControl)({
  width: "100%",
});

const ProjectEntry = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.elementBackground,

  "&.Mui-selected": {
    backgroundColor: theme.elementBackground,
  },
}));
