import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  StoreObject,
  concat,
  createHttpLink,
  defaultDataIdFromObject,
} from "@apollo/client";

import { API_URL } from "~/config";
import { UiStore } from "~/stores";

const authMiddleware = new ApolloLink((operation, forward) => {
  if (UiStore.token) {
    operation.setContext({
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${UiStore.token}`,
      },
    });
  }
  return forward(operation);
});

const httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
  credentials: "include",
});

export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({
    dataIdFromObject: (object: Readonly<StoreObject>) => {
      if (object.__typename === "Device") {
        return `${object.__typename}:${object.deviceuid}`;
      }
      return defaultDataIdFromObject(object);
    },
  }),
});
