import { gql } from "@apollo/client";

export const getProjectList = gql`
  query GetProjectList {
    getProjectList {
      projectList {
        id
        name
      }
    }
  }
`;
