/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Button, ButtonProps, Card, Grid, GridProps } from "@material-ui/core";
import { Theme, styled } from "@material-ui/core/styles";

export interface OpacityButtonProps extends ButtonProps {
  fullWidth?: boolean;
}

export const opacityButton = styled(
  ({ color, fullWidth, variant, ...rest }: OpacityButtonProps) => (
    <Button
      variant={variant ?? "contained"}
      color={color ?? "primary"}
      {...rest}
    />
  )
)(({ fullWidth, theme }: { fullWidth?: boolean; theme: Theme }) => ({
  textTransform: "none",
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  width: fullWidth ? "100%" : "unset",
}));

export const CenteredContainer = styled("div")({
  display: "flex",
  alignItems: " center",
  justifyContent: "center",
  height: "100%",
});

type Dimension =
  | string
  | number
  | ((props: unknown) => string | number | undefined);

interface SizedProps {
  height?: Dimension;
  width?: Dimension;
}

export const ElevableCard = styled(Card)(({ theme }) => ({
  "&:hover": {
    boxShadow: theme.shadows[5],
  },
}));

export const SizedGrid = styled(
  ({ width, height, ...rest }: GridProps & SizedProps) => <Grid {...rest} />
)(({ theme, height, width }: { theme: Theme } & SizedProps) => ({
  height,
  width,
}));
