import { action, observable } from "mobx";
import axios from "axios";

import { GetJwtPayload, User } from "@opacity/domain";

import { API_URL } from "~/config";
import { Logger } from "~/utils";

import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";

export class UiStore extends ChildStore {
  static token?: string;

  @observable
  user: User | null;

  @observable
  isAuthenticated: boolean;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.init();
  }

  @action
  init(): void {
    this.isAuthenticated = false;
  }

  @action
  login(token: string): void {
    this.isAuthenticated = true;
    UiStore.token = token;
    // TODO: set TTL for token?
  }

  @action
  logout(): void {
    this.isAuthenticated = false;
    UiStore.token = undefined;
    // TODO: send logout request to remove refresh token from cookie
  }

  @action
  setUser(user: User): void {
    this.user = user;
  }

  // TODO: call this on query fails in the application
  async silentTokenRefresh(): Promise<void> {
    try {
      const {
        data: { jwt },
      }: { data: GetJwtPayload } = await axios.get(
        `${API_URL}/auth/google/jwt`,
        {
          withCredentials: true,
        }
      );
      this.login(jwt);
    } catch (err) {
      Logger.error(err);
      this.logout();
    }
  }
}
