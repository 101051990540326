import React, { ChangeEvent, FC } from "react";
import { observer } from "mobx-react-lite";
import { TextField } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import { useStores } from "~/hooks";

interface TimePickerProps {
  target: "start" | "end";
}

export const TimePicker: FC<TimePickerProps> = observer(({ target }) => {
  const { timelineStore } = useStores();

  const activeDate =
    target === "start" ? timelineStore.startDate : timelineStore.endDate;

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const [hour, minute] = event.target.value
      .split(":")
      .map(value => parseInt(value, 10));
    if (!isNaN(hour) && !isNaN(minute)) {
      const newDate = activeDate.set("hour", hour).set("minute", minute);
      if (target === "start") {
        timelineStore.setStartDate(newDate);
      } else {
        timelineStore.setEndDate(newDate);
      }
    }
  };

  const selectedTime = `${String(activeDate.hour()).padStart(2, "0")}:${String(
    activeDate.minute()
  ).padStart(2, "0")}`;

  return (
    <StyledTextField
      id={`time-picker-${target}`}
      label={target === "start" ? "Starting time" : "Ending time"}
      type="time"
      value={selectedTime}
      onChange={handleTimeChange}
      inputProps={{
        step: 300, // 5 min
      }}
    />
  );
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(6),

  "& label": {
    whiteSpace: "nowrap",
  },
}));
