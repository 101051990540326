import { Dayjs } from "dayjs";

import { ID } from "@opacity/domain";

export const dayjsToUrl = (datetime: Dayjs): string => {
  return datetime.format("YYYY-MM-DD_HH-mm");
};

export const selectedProjectIdsToUrl = (
  selectedProjectIds: Set<ID>
): string => {
  if (selectedProjectIds.size === 0) {
    return "";
  }

  return `?p=${Array.from(selectedProjectIds).join(",")}`;
};
