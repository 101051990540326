import { action, computed, observable } from "mobx";
import dayjs, { Dayjs } from "dayjs";
import { TimelinesChartInstance } from "timelines-chart";

import { ID, Project } from "@opacity/domain";

import { ChildStore } from "./child.store";

export interface TimelineSettings {
  selectedProjectIds: Set<ID>;
  startDate: Dayjs;
  endDate: Dayjs;
}

export class TimelineStore extends ChildStore {
  @observable
  projects: Map<ID, Omit<Project, "devices">>;

  @observable
  selectedProjectIds: Set<ID>;

  @observable
  startDate: Dayjs;

  @observable
  endDate: Dayjs;

  @observable
  chart: TimelinesChartInstance;

  @observable
  zoomedStartDate: Dayjs;

  @observable
  zoomedEndDate: Dayjs;

  @computed
  get timeRange(): number {
    return this.startDate.diff(this.endDate);
  }

  @computed
  get zoomedTimeRange(): number | undefined {
    if (!this.zoomedStartDate || !this.zoomedEndDate) {
      return undefined;
    }
    return this.zoomedStartDate.diff(this.zoomedEndDate);
  }

  @action
  setProjects(projectList: Omit<Project, "devices">[]): void {
    this.projects = new Map<ID, Omit<Project, "devices">>();
    projectList
      .slice()
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .forEach(project => {
        this.projects.set(project.id, project);
      });
  }

  @action
  setSelectedProjectIds(projectIdList: Set<ID>): void {
    this.selectedProjectIds = new Set(projectIdList);
  }

  @action
  setStartDate(startDate: Dayjs): void {
    this.startDate = dayjs(startDate);
  }

  @action
  setEndDate(endDate: Dayjs): void {
    this.endDate = dayjs(endDate);
  }

  @action
  setChart(chart: TimelinesChartInstance): void {
    this.chart = chart;
  }

  @action
  setZoomedDateLimits(zoomedStartDate: Dayjs, zoomedEndDate: Dayjs): void {
    this.zoomedStartDate = zoomedStartDate;
    this.zoomedEndDate = zoomedEndDate;
  }
}
