import React, { FC, HTMLAttributes } from "react";
import { CircularProgress, Fade } from "@material-ui/core";
import { Theme, styled, useTheme } from "@material-ui/core/styles";

import { VivacityLogo, VivacityLogoProps } from "~/components/icons";
import { CenteredContainer } from "~/components/styledElements";

interface LoadingProps {
  isGreyScale?: boolean;
  isLogo?: boolean;
  size?: number;
}

export const Loading: FC<LoadingProps> = ({
  isGreyScale = false,
  isLogo,
  size = 100,
}) => {
  const theme = useTheme();
  const logoProps: Partial<VivacityLogoProps> = {};
  if (isLogo && isGreyScale) {
    logoProps.primary = theme.palette.grey[400];
    logoProps.secondary = theme.palette.grey[200];
  }
  return (
    <StyledCenteredContainer isGreyScale={isGreyScale}>
      {isLogo ? (
        <Fade in timeout={800}>
          <SizedLogo {...logoProps} />
        </Fade>
      ) : (
        <CircularProgress color="inherit" size={size} />
      )}
    </StyledCenteredContainer>
  );
};

const SizedLogo = styled(VivacityLogo)({
  height: "50%",
  width: "50%",
});

const StyledCenteredContainer = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isGreyScale,
    ...rest
  }: { isGreyScale: boolean } & HTMLAttributes<HTMLDivElement>) => (
    <CenteredContainer {...rest} />
  )
)(({ isGreyScale, theme }: { isGreyScale: boolean; theme: Theme }) => ({
  color: isGreyScale ? theme.palette.grey[400] : theme.palette.primary.main,
}));
