import React, { FC } from "react";
import { AppBar, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import { ApplyFilters } from "./ApplyFilters";
import { DateTimeSelect } from "./DateTimeSelect";
import { GraphControllers } from "./GraphControllers";
import { ProjectSelect } from "./ProjectSelect";

export const TimelineSettings: FC = () => {
  return (
    <ControlBarContainer component="nav" position="static" color="inherit">
      <Grid container justify="space-around" wrap="nowrap">
        <Grid
          container
          item
          xs={9}
          spacing={6}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <DateTimeSelect />
          </Grid>
          <Grid item xs={3}>
            <ProjectSelect />
          </Grid>
          <Grid item>
            <ApplyFilters />
          </Grid>
        </Grid>
        <Grid item>
          <GraphControllers />
        </Grid>
      </Grid>
    </ControlBarContainer>
  );
};

const ControlBarContainer = styled(AppBar)(({ theme }) => ({
  height: theme.controlBarHeight,
  marginBottom: theme.controlBarMargin,
  background: theme.elementBackground,
  boxShadow: theme.shadows[1],
  padding: `${theme.spacing()}px 0px`,
}));
